@charset "UTF-8";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800|Oswald:700|Crimson+Text:400,700|Pacifico|Nunito:200,300,400|Libre+Baskerville:400,700|Muli:200,300,500");
.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

/* Default */
.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

.material-icons.yellow {
  color: #FABC58; }

.material-icons.blue {
  color: #4688F1; }

.material-icons.fire {
  color: #f16d20; }

.material-icons.gray {
  color: #ddd; }

.material-icons.purple {
  color: #b880ec; }

.material-icons.black {
  color: black; }

.material-icons.green {
  color: #44C356; }

.material-icons.red {
  color: #e96767; }

.material-icons.maroon {
  color: maroon; }

.guarantee__main {
  *zoom: 1; }
  .guarantee__main:before, .guarantee__main:after {
    content: " ";
    display: table; }
  .guarantee__main:after {
    clear: both; }

/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

html {
  line-height: 1.9;
  font-size: calc(15px + .4vw); }

body {
  font-size: 1rem; }

h1 {
  font-size: 3.129em;
  line-height: 1.2; }

h2 {
  font-size: 2.353em;
  line-height: 1.2; }

h3 {
  font-size: 1.769em;
  line-height: 1.2; }

h4 {
  font-size: 1.33em; }

h5 {
  font-size: 1em; }

h6 {
  font-size: 0.752em; }

.section__body p + p {
  margin-top: 1rem; }

.container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 2rem;
  padding-left: 2rem; }
  @media (max-width: 1199px) {
    .container {
      max-width: 768px; } }

.container--has-background {
  background: #eee;
  padding: 1rem !important;
  border: 1px solid #ddd; }

.container-full {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem; }
  @media (max-width: 479px) {
    .container-full {
      padding: 0.5rem; } }

.container-half {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.75rem;
  padding-left: 1.75rem; }
  @media (max-width: 1199px) {
    .container-half {
      max-width: 768px; } }

.ribbon {
  position: fixed;
  left: 0;
  right: 0;
  transition: 0.3s;
  top: 0;
  height: 0.2rem;
  z-index: 9999;
  background: linear-gradient(90deg, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #a85e6c), color-stop(12.5%, #a85e6c), color-stop(12.5%, #e37f52), color-stop(25%, #e37f52), color-stop(25%, #ef9f48), color-stop(37.5%, #ef9f48), color-stop(37.5%, #9cb641), color-stop(50%, #9cb641), color-stop(50%, #009484), color-stop(62.5%, #009484), color-stop(62.5%, #21acb8), color-stop(75%, #21acb8), color-stop(75%, #1972dd), color-stop(87.5%, #1972dd), color-stop(87.5%, #8fa0ae), color-stop(100%, #8fa0ae));
  background-image: -webkit-linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: -moz-linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: -o-linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%); }

.ribbon__bottom {
  position: absolute;
  left: 0;
  right: 0;
  transition: 0.3s;
  bottom: 0;
  height: 0.2rem;
  z-index: 5;
  background: linear-gradient(90deg, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #a85e6c), color-stop(12.5%, #a85e6c), color-stop(12.5%, #e37f52), color-stop(25%, #e37f52), color-stop(25%, #ef9f48), color-stop(37.5%, #ef9f48), color-stop(37.5%, #9cb641), color-stop(50%, #9cb641), color-stop(50%, #009484), color-stop(62.5%, #009484), color-stop(62.5%, #21acb8), color-stop(75%, #21acb8), color-stop(75%, #1972dd), color-stop(87.5%, #1972dd), color-stop(87.5%, #8fa0ae), color-stop(100%, #8fa0ae));
  background-image: -webkit-linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: -moz-linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: -o-linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%);
  background-image: linear-gradient(left, #a85e6c 0%, #a85e6c 12.5%, #e37f52 12.5%, #e37f52 25%, #ef9f48 25%, #ef9f48 37.5%, #9cb641 37.5%, #9cb641 50%, #009484 50%, #009484 62.5%, #21acb8 62.5%, #21acb8 75%, #1972dd 75%, #1972dd 87.5%, #8fa0ae 87.5%, #8fa0ae 100%); }

body {
  font-family: "Open Sans", "Helvetica", sans-serif;
  color: #1F1F1F; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", "Helvetica Neue", sans-serif;
  color: #1F1F1F; }

::selection {
  color: #F9F9F9;
  background: #292F45; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ebebeb;
  margin: 1em 0;
  padding: 0; }

.jjj-label {
  font-weight: 700; }

.is-underlined {
  text-decoration: underline; }

.is-italicized {
  font-style: italic; }

.is-centered {
  margin: 0 auto;
  text-align: center; }

.is-blurred {
  filter: blur(0.25rem); }

.is-hidden {
  display: none !important; }

.hide {
  opacity: 0;
  transition: 0.3s all ease-in-out;
  pointer-events: none; }

.show {
  opacity: 1;
  pointer-events: auto; }

.webinar__body .container-full {
  text-align: center; }

.video {
  display: flex; }

@keyframes Wiggle {
  0% {
    transform: rotate(0); }
  25% {
    transform: rotate(-8deg); }
  75% {
    transform: rotate(8deg); }
  100% {
    transform: rotate(0); } }

.rocket-animation {
  display: inline-block;
  animation: .2s ease infinite Wiggle; }

.nav {
  background-color: white;
  display: flex;
  padding: 1rem 1rem;
  position: sticky;
  z-index: 9998;
  top: 0;
  align-self: flex-start;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F0F3F4; }
  .nav .logo {
    font-family: "Pacifico", "Helvetica", sans-serif;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    margin: 0 auto;
    color: #001828; }
  .nav .logo:link,
  .nav .logo:visited,
  .nav .logo:active,
  .nav .logo:hover {
    color: #3A3D4B; }
  .nav__menu {
    height: 100%; }
  .nav__items {
    display: flex;
    margin: 0;
    padding: 0; }
  .nav__item {
    list-style: none;
    margin: 0 1rem; }
  .nav__link {
    position: relative;
    font-family: "Oswald", "Helvetica Neue", sans-serif;
    text-transform: uppercase;
    display: block;
    font-size: 0.8rem;
    text-decoration: none;
    color: #3A3D4B; }
  .nav__link::after {
    transition: 0.3s all ease;
    position: absolute;
    content: "";
    left: 0;
    bottom: -3px; }
  .nav__link:hover::after {
    color: black;
    width: 100%;
    z-index: -1;
    bottom: 4px;
    height: 35%;
    background-color: #E6F1F5; }
  .nav__burger {
    height: 2.3rem;
    width: 3rem;
    display: none;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem; }
    .nav__burger.opened .bar {
      transition: top 100ms 230ms, transform 100ms, opacity 100ms;
      transition-timing-function: cubic-bezier(1, 0.05, 0.62, 1.78); }
      .nav__burger.opened .bar:first-of-type {
        top: 1em;
        transform: rotate(45deg); }
      .nav__burger.opened .bar:nth-of-type(2) {
        opacity: 0; }
      .nav__burger.opened .bar:nth-of-type(3) {
        top: 1em;
        transform: rotate(-45deg); }
    .nav__burger .bar {
      height: 0.33rem;
      background: linear-gradient(to right, #E37F52, #EF9F48);
      position: absolute;
      display: block;
      width: 100%;
      transition: top 100ms, transform 100ms 230ms, opacity 100ms 230ms;
      transition-timing-function: cubic-bezier(1, 0.05, 0.62, 1.78); }
      .nav__burger .bar:first-of-type {
        top: 0; }
      .nav__burger .bar:nth-of-type(2) {
        top: 1rem; }
      .nav__burger .bar:nth-of-type(3) {
        top: 2rem; }

@media (max-width: 767px) {
  .nav {
    flex-direction: column;
    align-items: flex-start; }
    .nav__burger {
      display: inline-block; }
    .nav__menu {
      display: none;
      width: 100%; }
    .nav__menu.active {
      display: flex; }
    .nav__items {
      width: 100%;
      flex-direction: column; }
    .nav__item {
      text-align: center; }
    .nav__link {
      padding: .5rem 1rem; } }

.section {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.section--has-background {
  background: url(/images/bg.png) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 4rem;
  will-change: transform;
  padding-bottom: 4.5rem;
  position: relative; }
  @media (max-width: 479px) {
    .section--has-background {
      background: #F9F9F9; } }

.section__title {
  text-align: center; }

.section__profile {
  margin: 0 -15px; }
  @media (max-width: 767px) {
    .section__profile {
      flex-wrap: wrap;
      justify-content: center; } }

.section__profile-img {
  padding: 0 15px; }

.section__profile-img img {
  width: 14rem;
  display: block;
  margin: 0 auto; }

.section__profile-info > p:first-child {
  margin-top: 0; }

@media (max-width: 479px) {
  .section__profile-info {
    padding: 2rem; } }

.intro h1 {
  margin-top: 3rem; }

.js-no-bs {
  position: relative;
  display: inline-block;
  min-width: 300px;
  font-family: "Open Sans", "Helvetica", sans-serif;
  color: #001828; }

.is-highlighted {
  background: #1F1F1F;
  color: #FFE834;
  padding: 0.5rem 1rem; }

.bar-wrap {
  position: relative; }

.product-intro img {
  width: 100%;
  height: auto; }

.jjj__module {
  display: flex; }
  @media (max-width: 479px) {
    .jjj__module {
      display: block; } }

.jjj__module-head {
  flex: 1;
  text-align: center; }

.jjj__module-head-number {
  font-size: 10rem;
  text-align: left;
  margin-top: 0;
  line-height: 1;
  letter-spacing: -40px;
  margin-right: 1rem;
  font-family: "Crimson Text", "Helvetica", sans-serif; }

.jjj__module-info {
  flex: 3; }

.jjj__module-info h3 {
  margin-bottom: 0; }

.badge {
  font-family: "Oswald", "Helvetica Neue", sans-serif;
  color: white;
  border-radius: 4px;
  padding: 0.1rem 0.4rem;
  margin-right: 0.4rem;
  font-size: 0.6rem;
  background: linear-gradient(to left, #ff9966, #ff5e62);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.badge--blue {
  background: #4688F1; }

.badge--green {
  background: #44C356; }

.jjj__module-info ol {
  list-style-type: circle;
  padding-left: 1rem; }

.gifs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.gif {
  height: auto;
  max-height: 400px;
  overflow: hidden;
  margin: 1rem;
  position: relative;
  border: 3px solid #f3f3f3;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  -webkit-transition: background-position 2s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.2s ease-in, background-position 2s ease-in-out, box-shadow 0.3s ease-in-out; }

.gif:hover {
  background-position: bottom center;
  box-shadow: 0px 10px 76px 4px rgba(0, 0, 0, 0.4);
  transform: scale(1.01); }

.tech {
  display: flex;
  padding-top: 4rem;
  padding-bottom: 4rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center; }

.tech__image {
  width: auto;
  max-height: 150px; }

.tech__image--small {
  width: 10rem;
  max-height: 100px; }

.cross-list {
  list-style-type: none;
  padding-left: 2rem; }

.cross-list li:before {
  margin-left: -1.3rem;
  color: #CE191F;
  font-size: 1.1rem;
  content: "\2717\0020"; }

.checkmark-list {
  list-style-type: none;
  padding-left: 2rem; }

.checkmark-list li:before {
  margin-left: -1.3rem;
  color: #50AE54;
  font-size: 1.1rem;
  content: "\2714\0020"; }

.guarantee .container {
  padding: 0; }

.guarantee__main {
  padding: 0 1rem;
  border: 3px dotted #F9F9F9; }

.guarantee__main .section__title:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 28%;
  padding-top: 2rem;
  border-bottom: 3px solid black; }

.guarantee__badge {
  border-radius: 50%;
  width: 10rem;
  height: auto;
  float: right; }
  @media (max-width: 767px) {
    .guarantee__badge {
      float: none;
      display: block;
      margin: 0 auto;
      width: 80%; } }

.payment {
  background: #eee; }

.payment__main {
  margin-top: 1rem;
  padding-top: 2rem;
  padding-bottom: 4rem; }

.payment__main .section__title {
  margin-top: 2rem; }

.payment__full {
  margin-top: 1rem;
  color: #7ec3f8;
  text-align: center;
  display: block;
  text-decoration: none; }

.cta {
  background-color: #E54A25;
  padding: 1rem 2rem;
  color: white;
  border-radius: 8px;
  width: 80%;
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 auto;
  text-decoration: none; }

.cta:hover {
  background-color: #fb2900; }

.cta--gray {
  background: #1a1a1a; }

.cta--gray:hover {
  background: linear-gradient(to right, #1c84a6, #3CB371); }

.return-to-top {
  border-radius: 50%;
  font-weight: bold;
  width: 2.6rem;
  height: 2.6rem;
  font-size: 1.2rem;
  background: #eee;
  color: #666;
  border: #eee;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  right: 20px;
  bottom: 80px;
  transition: 0.3s all ease;
  outline: none; }
  @media (max-width: 479px) {
    .return-to-top {
      display: none; } }

.return-to-top--dark {
  background: #ddd;
  color: black; }

.return-to-top:hover {
  color: #F9F9F9;
  background: #ddd;
  cursor: pointer; }

.webinar__cta {
  background: #FF512F;
  background: -webkit-linear-gradient(to right, #1c84a6, #3CB371);
  background: linear-gradient(to right, #1c84a6, #3CB371);
  text-decoration: none;
  font-weight: bold;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 20rem;
  text-align: center;
  transition: 0.3s all ease; }

.webinar__cta:hover {
  opacity: .9; }

.waitlist {
  padding-bottom: 5rem;
  background: #eee;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #fff, #eee);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #fff, #eee);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.waitlist .container {
  max-width: 1000px; }

.waitlist__form-label {
  display: block;
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: .5rem; }

.waitlist__form-input {
  display: block;
  padding: 1rem;
  width: 100%;
  outline: none;
  letter-spacing: 1.2px; }

.waitlist__form-input.name-field {
  text-transform: capitalize; }

.waitlist__subscribe-button {
  margin-top: 2rem;
  display: block;
  width: 100%;
  border: none;
  background-color: #FF512F;
  color: white;
  padding: 1rem 2rem;
  transition: all 0.3s ease-in; }

.waitlist__subscribe-button:hover {
  background: #1F1F1F;
  color: #FFE834;
  cursor: pointer; }

.small-text {
  text-align: center;
  font-size: 0.7rem; }

.success .container {
  max-width: 1200px !important; }

.success__title {
  text-align: center; }

.footer {
  border-top: 1px solid #bbbbbb;
  background-color: #EBEBEB;
  font-size: .67rem;
  padding: 1rem;
  color: #4D4D4D; }

.footer--dark {
  background-color: #1F1F1F;
  color: #bbbbbb; }

.footer p {
  margin: 0; }

.webinar__timer-warning {
  font-size: 0.77rem;
  font-weight: bold;
  text-align: center; }

.js-timer {
  display: flex;
  justify-content: space-between;
  text-align: center; }

.timer-part {
  margin: 0 0.1rem; }

.timer-part span {
  width: 5rem;
  display: block;
  background: black;
  color: white; }

.webinar__bullets p {
  font-size: 1rem;
  font-weight: bold; }

.webinar__bullets ul li {
  line-height: 1.4;
  font-size: 0.8rem;
  color: #4D4D4D;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  list-style-type: none; }

.webinar__bullets ul li:before {
  content: '🔥';
  color: transparent;
  text-shadow: 0px 0px 0.15px #ff3c00;
  margin-left: -1rem; }

.webinar__title {
  text-align: center; }

.webinar__cta--full {
  width: 100%; }

.webinar__body .container {
  border: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-content: stretch; }
  @media (max-width: 767px) {
    .webinar__body .container {
      display: block; } }

.webinar__host {
  flex: 1;
  background: #1F1F1F;
  padding: 0.5rem 0.5rem 4.1rem 0.5rem; }

.webinar__schedule {
  display: flex; }

.webinar__date-graphic {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: white; }

.webinar__date-graphic p:first-child {
  background: #1EC1A8;
  color: white;
  font-size: 0.7rem;
  margin: 0; }

.webinar__date-graphic p:nth-child(2) {
  font-size: 3.5rem;
  margin: 0;
  line-height: 1;
  color: #4D4D4D; }

.webinar__date-details {
  flex: 2;
  color: white;
  padding: 0 0.5rem; }

.webinar__date-details p {
  margin: 0; }

.webinar__day {
  font-size: 0.76rem; }

.webinar__date {
  border-bottom: 1px dashed #4D4D4D; }

.webinar__timezone {
  font-size: .6rem; }

.webinar__profile img {
  border-radius: 50%;
  width: 10rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  display: block; }

.webinar__profile p:nth-child(2) {
  color: #4D4D4D;
  text-align: center;
  margin: 0; }

.webinar__profile p:nth-child(3) {
  text-align: center;
  color: #F9F9F9;
  margin: 0; }

.webinar__details {
  padding: 0.8rem;
  flex: 1;
  position: relative; }

.webinar__bullets p {
  line-height: 5rem; }
  @media screen and (min-width: 2000px) {
    .webinar__bullets p {
      line-height: 2.5rem; } }

#live-chat {
  height: 100%;
  min-height: 550px;
  border: 1px solid black; }

.pricing {
  display: flex;
  justify-content: space-around; }
  @media (max-width: 767px) {
    .pricing {
      display: block; } }

.pricing__block {
  flex: 1;
  border-radius: 4px;
  font-size: 0.8rem;
  margin: 1rem;
  border: 2px solid #eee;
  padding: .5rem 2rem 2rem .5rem; }
  @media (max-width: 479px) {
    .pricing__block {
      padding-left: 0rem;
      margin-bottom: 1rem; } }

.pricing__course,
.pricing__tier {
  text-align: center;
  line-height: 0.7; }

.pricing__list {
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 1rem; }

.pricing__item::before {
  margin: .2rem .4rem 0rem -1.1rem;
  color: #50AE54;
  font-size: 0.8rem;
  content: "\25CE"; }

.pricing__item--master::before {
  margin: .2rem .4rem 0rem -1.1rem;
  color: #e97629;
  font-size: 0.7rem;
  content: "\25C9"; }

.pricing__tagline {
  font-family: "Oswald", "Helvetica Neue", sans-serif;
  padding-top: 1rem;
  font-weight: bold;
  font-size: 1.8rem; }

.pricing__plan {
  font-style: italic;
  padding: 0rem 0 0rem 0;
  font-size: 1rem; }

.pricing__full {
  margin-top: .5rem;
  text-align: center;
  color: #006fc6;
  display: block; }

.pricing__trigger {
  border-radius: 4px;
  background: #CB4C30;
  padding: .8rem 1.2rem;
  text-align: center;
  text-decoration: none;
  width: 80%;
  margin: 0 auto;
  color: white;
  display: block;
  font-size: 1rem;
  transition: 0.3s all ease; }

.pricing__trigger:hover {
  opacity: 0.9; }

.spacer {
  height: 4px;
  width: 50px;
  margin: .5rem auto;
  background: #d4ecf5;
  border-radius: 3px; }

.updates {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem; }

.updates__item {
  width: 33.33%;
  text-align: center;
  padding: .7rem .5rem; }
  @media (max-width: 767px) {
    .updates__item {
      width: 50%; } }
  @media (max-width: 479px) {
    .updates__item {
      width: 100%; } }

.updates__title {
  margin: 0;
  padding: 0;
  line-height: .8;
  color: #7b8186;
  font-family: "Crimson Text", "Helvetica", sans-serif; }

.updates__details {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  line-height: 1.5; }

.masterclass__title {
  text-align: center; }

.masterclass__body {
  display: flex;
  justify-content: space-around; }

.masterclass__video {
  width: 67%;
  margin: .5rem; }
  .masterclass__video > iframe {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto; }

.masterclass__chat {
  width: 33.33%;
  margin: 0.5rem; }
  .masterclass__chat > iframe {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto; }

#testimonials h2 {
  margin-bottom: 4rem; }

.testimonials__item {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic; }

.testimonials__paragraph {
  width: 67%; }

.testimonials__author {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  font-style: normal; }
  .testimonials__author img {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover; }

.divider {
  overflow: visible;
  /* For IE */
  height: 30px;
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 0;
  border-radius: 20px; }

.divider:before {
  /* Not really supposed to work, but does */
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 1px 0;
  border-radius: 20px; }

.quote {
  font-size: 1.4rem;
  font-weight: bold;
  color: #4D4D4D; }

.review-modal {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  transition: visibility 0s linear 0s, opacity 500ms ease 0s; }

.review-modal .container {
  position: fixed;
  width: 100%;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  padding: 1rem;
  margin: 0;
  z-index: inherit; }

.review-modal .close-review-modal {
  position: absolute;
  top: 0.6rem;
  font-weight: 200;
  right: 1.4rem;
  color: #1F1F1F;
  border-radius: 50%;
  font-size: 1.2rem; }

.review-modal .close-review-modal:hover {
  cursor: pointer; }

.review-modal .review {
  transform: none;
  max-height: 520px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0; }

::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bbb;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa; }

.review-modal .review:hover,
.review-modal .review:focus {
  transform: none;
  box-shadow: 0 0.125rem 1.25rem 0 rgba(203, 203, 203, 0.5); }

.rIntro {
  padding: 1rem 0;
  background-color: #f6f6f6; }

.rIntro__title {
  text-align: center; }

.heart-review {
  display: inline-block;
  font-size: 2.5rem;
  text-align: center;
  color: #e33535;
  animation: beat 1s infinite; }

@keyframes beat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out; }
  10% {
    transform: scale(1.1);
    animation-timing-function: ease-in; }
  15% {
    transform: scale(1);
    animation-timing-function: ease-out; }
  25% {
    transform: scale(1.05);
    animation-timing-function: ease-in; }
  35% {
    transform: scale(1);
    animation-timing-function: ease-out; } }

.reviews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0.78rem;
  background-color: #F6F6F6;
  padding: 2rem;
  justify-content: space-between;
  font-family: "Nunito"; }

.review {
  background: white;
  border-radius: 4px;
  flex: 1 1 30%;
  font-family: Nunito;
  padding: 1rem;
  box-shadow: 0 0.125rem 1.25rem 0 rgba(203, 203, 203, 0.5);
  transition: .22s ease-out;
  z-index: inherit; }

.review:hover,
.review:focus {
  box-shadow: 0 1.25rem 1.875rem 0 #e6e6e6;
  transform: translate3d(0, -0.1875rem, 0); }

.review__bio {
  display: flex;
  font-size: 0.68rem;
  align-items: center; }

.review__pic {
  width: 60px;
  margin-right: 1rem; }

.review__pic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  width: 60px;
  height: 60px; }

.review__name {
  margin: 0; }

.review__country {
  font-weight: 200; }

.review__stars {
  --star-size: 1.2rem;
  --star-color: #fff;
  --star-background: #fc0;
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1; }
  .review__stars::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.review__tagline {
  font-family: Nunito;
  line-height: 1.2;
  font-weight: 300; }

.review__note {
  font-weight: 200;
  margin-top: 0rem;
  font-size: 0.68rem; }

.review__CR {
  text-decoration: none;
  font-weight: bold;
  color: #1F1F1F; }
